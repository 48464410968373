import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Über uns`}</h1>
    <p>{`Wir sind Ihr Technologiepartner für Beratung und Entwicklung von digitalen Produkten. Ob Administratoren, Texter, Illustratoren, Fotografen, Programmierer oder Druckereien... bei Bedarf können diese auch in ihr Projekt miteinbezogen werden, um optimale Ergebnisse zu erzielen.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      