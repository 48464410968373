import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Projekte`}</h1>
    <ProjectCard title="KDSweb" thumbnailPath="https://kdsweb.de/static/bg-82161f4f8613da018fa3f0a81855f9c9.jpg" link="https://kdsweb.de/" bg="#1b1f22" textColor="white" mdxType="ProjectCard">
 Entwicklung und Design responsiver Webseiten für Wordpress und Shops
auf WooCommerce Basis.
    </ProjectCard>
    <ProjectCard title="MGV Edelweiß Kell e.V." thumbnailPath="https://gesangverein-edelweiss-kell.de/static/5e9d3d71ad0e16073f4ac3b12e03e30b/47498/Maennerchor2005.jpg" link="https://gesangverein-edelweiss-kell.de/" bg="#00d1b2" textColor="white" mdxType="ProjectCard">
  Seite des Männergesangvereins, der sich in den vergangenen zwei Jahrzehnten erweitert und zeitgemäß entwickelt hat. Neben dem Traditionschor, dem Männerchor, sind unter dem Vereinsdach noch ein gemischter Chor und – seit kurzem – ein Projektchor vereint.
    </ProjectCard>
    <ProjectCard title="Berghof" thumbnailPath="http://kell.info/images/cropped-IMG-20170408-WA0004.jpg" link="https://berghof.org/" bg="#a50000" textColor="white" mdxType="ProjectCard">
 Ein netter kleiner Privatstall mit inzwischen elf glücklichen Pferdchen und ihren Zweibeinern. Daß diese vorwiegend weiblichen Zweibeiner ebenfalls Spaß miteinander haben ist das beste an der Sache und ein echter Glücksfall.
    </ProjectCard>
    <ProjectCard title="Lubentiusweg" thumbnailPath="http://lubentiusweg.de/wp-content/uploads/2016/11/slide1.jpg" link="http://lubentiusweg.de/" bg="#454545" textColor="white" mdxType="ProjectCard">
   Der Pilgerweg von Kell über Kobern und Arnstein nach Dietkirchen. Auf den Spuren von Lubentius, alle Wirkungsstätten des Missionars auf einer ca. 150 km langen Gesamtstrecke in 6 Etappen.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      